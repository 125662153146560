body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto", sans-serif;
}

.hover-trigger .hover-target {
  visibility: hidden;
  width: 120px;
  background-color: #FFFFFF;
  color: #273359;
  text-align: center;
  border-radius: 6px;
  padding: 10px 0;
  position: absolute;
  z-index: 1;
  top: 120%;
  left: 50%;
  margin-left: -60px;
  transition-timing-function: ease-in-out;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.hover-trigger .hover-target::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -10px;
  border-width: 12px;
  border-style: solid;
  border-color: transparent transparent white transparent;
}

.hover-trigger:hover .hover-target {
  transition-delay: 0.5s;
  visibility: visible;
  opacity: 1;
}

.credit-card-list{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 25px #0000001A;
  border-radius: 5px;
  opacity: 1;
}

#account-list-scroll::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/

}
#account-list-scroll::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /* background: red; */
}
#account-list-scroll::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  border-radius: 1rem;
  /* outline: 1px solid slategrey; */
}

#tab-list-scroll::-webkit-scrollbar {
  width: 4px;
  cursor: pointer;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/

}
#tab-list-scroll::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  cursor: pointer;
  /* background: red; */
}
#tab-list-scroll::-webkit-scrollbar-thumb {
  cursor: pointer;
  background-color: #a0aec0;
  border-radius: 1rem;
  /* outline: 1px solid slategrey; */
}

.selected-card-png:hover {
  cursor: pointer;
  /* -webkit-filter: drop-shadow(2px 1px 0 white)
  opacity(.5) drop-shadow(-1px -1px 0 white);
  filter: drop-shadow(2px 1px 0 white) 
  opacity(.5) drop-shadow(-1px -1px 0 white); */
  /* -webkit-filter: opacity(.5) drop-shadow(0 0 0 white);
  filter: opacity(.5) drop-shadow(0 0 0 white); */
  /* filter: sepia(100%);
  -webkit-filter: sepia(100%); */
}

.delete-card-png:hover {
  cursor: pointer;
  /* -webkit-filter: drop-shadow(2px 1px 0 red)
                  drop-shadow(-1px -1px 0 red);
  filter: drop-shadow(2px 1px 0 red) 
          drop-shadow(-1px -1px 0 red); */
  -webkit-filter: opacity(.5) drop-shadow(0 0 0 red);
  filter: opacity(.5) drop-shadow(0 0 0 red);
  /* filter: sepia(100%);
  -webkit-filter: sepia(100%); */
}

.user-body-card {
  box-shadow: '0px 5px 25px #00000029';
	border-radius: '8px';
	opacity: 1
}

.plan-items-card {
  background: transparent url('./assets/rectangle.png') 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 25px #0000003D;
  border-radius: 5px;
  opacity: 1;
}

.forgot-password-div {
  background: #FFFFFF33 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 25px #00000029;
  border-radius: 5px;
  opacity: 1;
}

.active-account-row{
  background: #E6E6E6 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 25px #00000029;
  border-radius: 5px;
  opacity: 1;
}