.App{
  background-color: #FFFFFF;
  color: white;
}
.PurpleBack{
  fontWeight: 'bold';
  width: '100%';
  background-color: '#673ab7';
  color: '#FFFFFF';
  paddingTop: 10;
  paddingBottom: 10;
  marginTop: 10
}